<template>
  <el-main>
    <el-form ref="form" :model="ruleForm" label-width="140px" :rules="rules">
      <el-form-item label="开启活动弹框：" prop="is_open">
        <el-switch v-model="ruleForm.is_open" :active-value="1" :inactive-value="0"></el-switch>
        <span class="tips">开启后，用户进入首页则弹出活动弹框</span>
      </el-form-item>
      <el-form-item label="活动弹框封面：" prop="cover_picture">
        <ReadyUploadSource
          :path="ruleForm.cover_picture || ''"
          @getSource="val => (ruleForm.cover_picture = val.path)"
          @removeThis="ruleForm.cover_picture = ''"
          :showStyle="{
            width: '120px',
            height: '120px',
          }"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="选择跳转活动：" prop="jump_url">
        <el-input style="width: 400px" v-model="ruleForm.jump_url" disabled placeholder="请选择此弹框跳转活动"></el-input>
        <el-button type="primary" @click="startChoose">选择</el-button>
      </el-form-item>
      <el-form-item label="弹出方式：" prop="type">
        <el-radio-group v-model="ruleForm.type">
          <el-radio :label="0">每次进入都弹出</el-radio>
          <el-radio :label="1">用户点击后不再弹出</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="phoneBox">
      <div class="phoneContent">
        <div class="popup_main"><img v-if="ruleForm.cover_picture" :src="ruleForm.cover_picture" alt="" /></div>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import ToPage from '@/views/addPages/toPage/index';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
    ToPage,
  },
  data() {
    function checkCover(that, value, callback) {
      if (that.ruleForm.is_open) {
        if (!value) {
          callback(new Error('请上传活动弹框封面'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    function checkJump(that, value, callback) {
      if (that.ruleForm.is_open) {
        if (!value) {
          callback(new Error('请选择跳转活动'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    return {
      ruleForm: {
        is_open: 0,
        cover_picture: '',
        jump_url: '',
        type: 0,
      },
      rules: {
        is_open: [{ required: true }],
        cover_picture: [{ required: true, validator: (rule, value, callback) => checkCover(this, value, callback), trigger: 'change' }],
        jump_url: [{ required: true, validator: (rule, value, callback) => checkJump(this, value, callback), trigger: 'change' }],
        type: [{ required: true }],
      },
      toPageFlag: false,
    };
  },
  created() {
    this.getPageSet();
  },
  methods: {
    startChoose() {
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    Preservation() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.movableBulletBox.edit, this.ruleForm).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getPageRes(data) {
      let { wxroute } = data;
      this.ruleForm.jump_url = wxroute;
      this.toPageFlag = false;
    },
    getPageSet() {
      this.$axios.post(this.$api.movableBulletBox.info).then(res => {
        if (res.code === 0) {
          if (!res.result) return;
          for (const key in this.ruleForm) {
            this.ruleForm[key] = res.result[key];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  .tips {
    font-size: 14px;
    color: #b1b1b1;
    margin-left: 20px;
  }
  .phoneBox {
    width: 350px;
    height: 700px;
    background-image: url('../../assets/share-back.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    flex-shrink: 0;
    .phoneContent {
      width: 332px;
      height: calc(100% - 99px);
      position: absolute;
      top: 92px;
      left: 10px;
      border-radius: 0 0 30px 30px;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      .popup_main {
        width: 250px;
        height: 250px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
